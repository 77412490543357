<template>

  <div class="container ">
    <div v-if="bt_data == null" class="error404"><p>{{ 'BRIGADE_NOT_FOUND' | translate }}</p></div>
    <div v-else>
      <div v-if="loading_main" class="loading-wrap">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    <div class="brgd_taniltsuulga">
      <div class=" col-sm-12 header_margin"><!-- HEADER MARGIN --> </div>
      <div class="row">
        <!--LEFT COLUMN-->
        <div class="col-12 col-md-12 col-lg-8 brgd_tan">
          <div class="left_col_cont">
            <!--COMPANY DESC-->
            <div class="col-sm-12 " style="border-bottom: 1px solid #F1F3F9; margin-bottom: 8px;">
              <table class="company_de_cont">
                <tr>
                  <td class="rate_star_cont">
                    <div v-if="bt_data.torol_gishuun">
                      <img src="../../assets/pic/star.svg"  class="left " alt="">
                    </div>
                    <div v-else>
                      <img src="../../assets/pic/star.svg"  class="left " alt="" v-if="bt_data.gishuunbus_torol === 'Алтан багц'">
                    </div>
                  </td>
                  <td class="rcc_img_cont ">
                    <div class="rcc_img_cont2">
                      <img :src="image_url_mbua + bt_data.logo" alt="">
                    </div>
                  </td>
                  <td class="company_name_cont">
                    <b>{{bt_data.ner_mn}}</b>
                  </td>
                  <td class=" member_logo_cont ">
                    <div v-if="bt_data.torol_gishuun">
                      <img class="logo right" src="../../assets/gold_logo.svg" alt="" v-if="bt_data.torol_gishuun === 'Алтан багц'">
                      <img class="logo right" src="../../assets/silver_logo.svg" alt="" v-if="bt_data.torol_gishuun === 'Мөнгөн багц'">
                      <img class="logo right" src="../../assets/bronze_logo.svg" alt="" v-if="bt_data.torol_gishuun === 'Хүрэл багц'">
                    </div>
                    <div v-else>
                      <img class="logo right" src="../../assets/company_logo/gish_bus_altan.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Алтан багц'">
                      <img class="logo right" src="../../assets/company_logo/gish_bus_mungun.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Мөнгөн багц'">
                      <img class="logo right" src="../../assets/company_logo/gish_bus_hurel.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Хүрэл багц'">
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <!--TABS -->
            <div class="col-sm-12 for_responsive">
              <Tabs value="name1"   v-model="tab" class="brgd_taniltsuulga_tab_head">
                <!-- ТАНИЛЦУУЛГА TAB-->
                <TabPane :label="'introduction' | translate" class="brgd_tan_tab1 " name="name1">
                  <div class="main">
                    <div class="row">
                      <div class="col-sm-12">
                        <img class="brgd_taniltsuulga_img"
                             :src="image_url_mbua + this.bt_data.banner" alt="">
                      </div>
                      <div class="col-sm-12 brgd_tan_tab1_tan_title_cont">
                        <p class="brgd_tan_tab1_tan_title brgd_tab_left_set">{{ 'introduction' | translate }}</p>
                      </div>
                      <div class="col-sm-12 brgd_tan_tab1_tan_txt">
                        <p v-html="bt_data.tuhai"> </p>
                      </div>
                      <div class="col-sm-12">
                        <p class="brgd_tan_tab1_tan_title">{{ 'direction_activity' | translate }}</p>
                      </div>
                      <div class="col-sm-12 brgd_tan_tab1_uach_txt">
                        <p v-for=" (uachiglel, index) in bt_ua_chiglel" :key="index">
                          <b> &#183;</b>
                          {{uachiglel.brigad_uil_ajilgaa_chiglelcol}}
                        </p>
                      </div>
                      <div class="col-sm-12">
                        <p class="brgd_tan_tab1_tan_title">{{'partner_organizations' | translate}}</p>
                      </div>
                      <!-- SWIPER HERE-->
                      <div class="col-12">
                        <swiper class="swiper123" :options="medee_medeelel_slide">
                          <swiper-slide v-for="(btxbai, index) in bt_xamtragch_baiguullaga" :key="index">
                            <div class="brgd_tan_xamt_baiguullaga">
                              <router-link :to="'/baiguullaga_tanilts/' + btxbai.hamtragch_id">
                                <div>
                                  <a href="#">
                                    <img :src="image_url_mbua+(btxbai.logo)" alt="">
                                  </a>
                                </div>
                                <p>{{btxbai.ner_mn}}</p>
                              </router-link>
                            </div>
                          </swiper-slide>
                          <div class="swiper-pagination" slot="pagination"></div>
                        </swiper>
                      </div>
                      <div class="col-sm-12 brgd_tan_baig_unelgee " style="margin-bottom: 15px;">
                        <div class="col-sm-12 title_cont">
                          <Button type="primary" class="sanal_huselt_btn read_more" @click="sanalShow = true">
                              {{ 'sanaL_hus' | translate }}
                          </Button>
                        </div>
                      </div>

                      <div class="col-sm-12 brgd_tan_baig_unelgee" >
                        <div class="row">
                          <div class="col-12">
                            <p class="title">{{ 'evaluation_customer_performance' | translate }}</p>
                          </div>
                          <div class="rt_hr"></div>
                          <div class="col-sm-12 lists">
                            <p class="float-left"> {{ 'payment' | translate }}</p>
                            <p class="float-right"><Rate class="rate" v-model="rate_guits_cash"/> </p>
                          </div>
                          <div class="col-sm-12 lists">
                            <p class="float-left">{{ 'work_carried_out_according_plan' | translate }}</p>
                            <p class="float-right"><Rate class="rate" v-model="rate_guits_accord_plan"/> </p>
                          </div>
                          <div class="col-sm-12 lists">
                            <p class="float-left"> {{ 'Making_optimal_decisions_progress_work' | translate }}</p>
                            <p class="float-right"><Rate class="rate" v-model="rate_exp_decision"/> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>

                <!-- ГҮЙЦЭТГЭСЭН АЖЛУУД TAB   -->
                <TabPane :label="'projects' | translate" name="name2">
                  <div class="row">
                    <div class="col-sm-12 brgd_guitsetgesen_ajluud_cont" v-if="guitset_ajil_srch_bid.length > 0">
                      <div class="row sub_cont" v-for=" (bga, index) in guitset_ajil_srch_bid" :key="index">
                        <div class="col-sm-4 col-md-3 col-4 image">
                          <img :src="image_url_mbua+persPic(bga.zurag)"  alt="" v-if="bga.zurag != ''">
                          <!--:src="IMAGE_URL + baiguullaga.logo" alt="" v-if="baiguullaga.logo != ''"-->
                        </div>
                        <div class="col-8 col-sm-8 col-md-6 center_txt">
                          <a @click="modalCall(bga.id)" >
                            <p class="main_txt">{{bga.brigad_uil_ajilgaa_chiglelcol}}</p>
                            <p><b>{{ 'customer' | translate }}: </b> {{bga.brigad_ner}} </p>
                            <p><b>{{ 'where' | translate }}: </b> {{bga.aimagname}}</p>
                          </a>
                        </div>
                        <div class="col-12 col-sm-12 col-md-3  left_txt">
                          <div class="row">
                            <div class="col-6 ">
                              <div class="date">
                                <p>{{ 'start_date' | translate }}</p>
                                <p class="date_dec">{{bga.e_ognoo |date }}</p>
                              </div>
                            </div>
                            <div class="col-6 sub_left_border">
                              <div class="date">
                                <p>{{ 'completion_date' | translate }}</p>
<!--                                <p class=" date_dec_blue"><b>{{bga.d_ognoo |date }}</b></p>-->
                                  <p class=" date_dec_blue" v-if="bga.d_ognoo === '' && bga.d_ognoo === null">
                                      <b>{{'inProgress' | translate}}</b>
                                  </p>
                                  <p v-else class=" date_dec_blue" >
                                      <b>{{ bga.d_ognoo | date }}</b>
                                  </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="pagenation pagenation_edit">
                        <Page @on-change="changePageGa"
                              :current-page="current_page_ga"
                              :page-size="10"
                              :total="total_ga"
                              size="small"
                        />
                      </div>
                      <!-- unelgeenii xeseg-->
<!--                      <Tabs value="name1" class="rate_tabs">-->
<!--                        <TabPane label="ЗАХИАЛАГЧИД ӨГСӨН ҮНЭЛГЭЭ" name="name1">-->
<!--                          <div class="col-sm-12 brgd_tan_baig_unelgee" >-->
<!--                            <div class="row">-->
<!--                              &lt;!&ndash;                              <div class="rt_hr"></div>&ndash;&gt;-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Төлбөр тооцоо</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_b_cash"/> </p>-->
<!--                              </div>-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Гэрээт ажлын гүйцэтгэл</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_excution"/> </p>-->
<!--                              </div>-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Ажилчдын чадавх, чадамж</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_emp_potential"/> </p>-->
<!--                              </div>-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Гүйцэтгэсэн ажлын хариуцлага</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_incumbency"/> </p>-->
<!--                              </div>-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Цаг барилт</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_correcthess"/> </p>-->
<!--                              </div>-->


<!--                            </div>-->
<!--                          </div>-->
<!--                        </TabPane>-->
<!--                        <TabPane label="ЗАХИАЛАГЧААС ГҮЙЦЭТГЭЛД ӨГСӨН ҮНЭЛГЭЭ" name="name2">-->
<!--                          <div class="col-sm-12 brgd_tan_baig_unelgee" >-->
<!--                            <div class="row">-->
<!--                              &lt;!&ndash;                          <div class="col-sm-12 title_cont">&ndash;&gt;-->
<!--                              &lt;!&ndash;                            <p class="brgd_tan_tab1_tan_title "> ЗАХИАЛАГЧААС ГҮЙЦЭТГЭЛД ӨГСӨН ҮНЭЛГЭЭ</p>&ndash;&gt;-->
<!--                              &lt;!&ndash;                          </div>&ndash;&gt;-->
<!--                              <div class="rt_hr"></div>-->
<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Төлбөр тооцоо</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_guits_cash"/> </p>-->
<!--                              </div>-->
<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Гүйцэтгэх ажлын явц төлөвлөгөөны дагуу явагдсан эсэх</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_guits_accord_plan"/> </p>-->
<!--                              </div>-->

<!--                              <div class="col-sm-12 lists">-->
<!--                                <p class="float-left"> Тухайн ажлын гүйцэтгэлийн явцын оновчтой шийдвэр гаргалт</p>-->
<!--                                <p class="float-right"><Rate class="rate" v-model="rate_exp_decision"/> </p>-->
<!--                              </div>-->

<!--                            </div>-->
<!--                          </div>-->
<!--                        </TabPane>-->
<!--                      </Tabs>-->
                    </div>
                    <div v-else class="no-data notfoundata">
                        {{ 'There_no_work_done' | translate }}
                    </div>
                  </div>
                </TabPane>

                <!-- АЖЛЫН ЗАР TAB-->
                <TabPane :label="'job_posting' | translate" name="name3">
                  <!--Right table list (Ажлын зарын лист)-->
                  <div class="aolgogch_job_news">
                    <div class="right">
                      <div class="row">
                        <div class="col-12 p_table"  v-if="b_ajliin_zar.length > 0">
                          <table width="100%">
                            <tbody>
                            <!--tr-->
                            <tr v-for="(zar, index) in b_ajliin_zar" :key="index">
                              <td>
                                <div class="company_logos" v-if="bt_data.torol_gishuun">
                                  <img class="logo right" src="../../assets/company_logo/1_gold.svg" alt="" v-if="bt_data.torol_gishuun === 'Алтан багц'">
                                  <img class="logo right" src="../../assets/company_logo/1_silver.svg" alt="" v-if="bt_data.torol_gishuun === 'Мөнгөн багц'">
                                  <img class="logo right" src="../../assets/company_logo/1_bronze.svg" alt="" v-if="bt_data.torol_gishuun === 'Хүрэл багц'">
                                </div>
                                <div class="company_logos" v-else>
                                  <img class="logo right" src="../../assets/company_logo/2_gold.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Алтан багц'">
                                  <img class="logo right" src="../../assets/company_logo/2_silver.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Мөнгөн багц'">
                                  <img class="logo right" src="../../assets/company_logo/2_bronze.svg" alt="" v-if="bt_data.torol_gishuun_bus === 'Хүрэл багц'">
                                </div>
                                <div class="p_logo">
                                  <img :src="image_url_mbua+(bt_data.logo)" alt="" v-if="bt_data.logo != ''"  >
                                </div>
                              </td>
                              <td>
                                <div class="col_1 ">
                                  <router-link :to="'/br_a_z_details/' + zar.id"><h5>
                                    {{zar.ajiliin_torol}}</h5>
                                  </router-link>
                                  <div class="flex_col">
                                    <div class="sub_col_2">
                                      <span class="company_n">{{bt_data.ner_mnbrigad}} </span>
                                      <div class="text_bold">{{ 'where' | translate }}:<span
                                          class="not_bold"> {{zar.aimagname}}</span>
                                      </div>
                                    </div>
                                    <div class="static_button ">
                                      {{zar.tsagiin_torol}}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <save_button_brigad_ajil_zar :zar_id="zar.id" type="brigad"></save_button_brigad_ajil_zar>
                                <div class="td_3">
                                  <div class="td_3_1">
                                    <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                    <p class="diff_p_text">{{zar.e_ognoo | date }}</p>
                                  </div>
                                  <div class="td_3_2">
                                    <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                    <p class="p_text ">{{zar.d_ognoo | date }}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="pagenation pagenation_edit">
                            <Page @on-change="changePage"
                                  :current-page="current_page"
                                  :page-size="10"
                                  :total="total"
                                  size="small"
                            />
                          </div>
                        </div>
                        <div v-else class="no-data">
                            {{'no_job_postings' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>

                <!-- АЖЛЫН БАЙРНЫ ЗАР TAB-->
                <TabPane :label="'ajliin_zar' | translate" name="name4">
                  <!--Right table list (Ажлын байрны зарын лист)-->
                  <div class="aolgogch_job_news">
                    <div class="right">
                      <div class="row">
                        <div class="p_table"  v-if="aj_bair_zar_baiguullaga.length > 0">
                          <table width="100%">
                            <tbody>
                            <!--tr-->
                            <tr v-for="(zar, index) in aj_bair_zar_baiguullaga" :key="index">
                              <td>
                                <div class="company_logos" v-if="zar.gishuun">
                                  <img class="logo" src="../../assets/company_logo/1_gold.svg" alt="" v-if="zar.gishuun === 'Алтан багц'">
                                  <img class="logo" src="../../assets/company_logo/1_silver.svg" alt="" v-if="zar.gishuun === 'Мөнгөн багц'">
                                  <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt="" v-if="zar.gishuun === 'Хүрэл багц'">
                                </div>
                                <div class="company_logos" v-else>
                                  <img class="logo" src="../../assets/company_logo/2_gold.svg" alt="" v-if="zar.gishuun_bus === 'Алтан багц'">
                                  <img class="logo" src="../../assets/company_logo/2_silver.svg" alt="" v-if="zar.gishuun_bus === 'Мөнгөн багц'">
                                  <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt="" v-if="zar.gishuun_bus === 'Хүрэл багц'">
                                </div>
                                <div class="p_logo">
                                  <img :src="image_url_mbua+(bt_data.logo)" alt="" v-if="bt_data.logo != ''"  >
                                </div>
                              </td>
                              <td>
                                <div class="col_1 ">
                                  <router-link :to="'/br_a_b_zar_details/' + zar.id"><h5>
                                    {{zar.mergejil}}</h5>
                                  </router-link>
                                  <div class="flex_col">
                                    <div class="sub_col_2">
                                      <span class="company_n">{{bt_data.ner_mn}} </span>
                                      <div class="text_bold">{{ 'where' | translate}}: <span
                                          class="not_bold"> {{zar.aimagname}}</span>
                                      </div>
                                    </div>
                                    <div class="static_button ">
                                      {{zar.tsagiin_torol}}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <save_button_brigad_ajil_bair_zar :zar_id="zar.id" type="brigad"></save_button_brigad_ajil_bair_zar>
<!--                                <save_button_ajil_zar :zar_id="zar.id" class=" save_btn_edited" type="baiguullaga"></save_button_ajil_zar>-->
                                <div class="td_3">
                                  <div class="td_3_1">
                                    <p>{{ 'zarlasan_ognoo' | translate }}</p>
                                    <p class="diff_p_text">{{zar.e_ognoo | date }}</p>
                                  </div>
                                  <div class="td_3_2">
                                    <p>{{ 'anket_avah_ognoo' | translate }}</p>
                                    <p class="p_text ">{{zar.d_ognoo | date }}</p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          <div class="pagenation pagenation_edit">
                            <Page @on-change="changePageB"
                                  :current-page="current_page_b"
                                  :page-size="10"
                                  :total="total_b"
                                  size="small"
                            />
                          </div>
                        </div>
                        <div v-else class="no-data">
                            {{ 'no_ads' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>

        <!--  RIGHT COLUMN -->
        <div class="col-12 col-md-12 col-lg-4 brgd_tan brgd_tan_no_padd">
          <div class="right_col_cont">
            <div class="row" style="margin-bottom: 15px;">
              <div class="col-sm-12">
                <div class="brgd_tan_left_col_img">
                  <img :src="image_url_mbua + bt_data.logo"  alt="">
                </div>
              </div>
              <div class="col-sm-12 brgd_lc_ognootxt_cont">
                <p style="color: #7F889E;">{{ 'Date_commencement_operations' | translate }}:
                    <span style="color: #2C3B63;">{{bt_data.e_ognoo | date }}</span>
                </p>
              </div>
              <div class="col-sm-12">
                <div class="right-tabs">
                  <Tabs value="name1">
                    <TabPane :label="'ajliin_bairnii_zaruud' | translate" name="name1">
                      <div class="row ">
                        <Scroll height="500">
                          <div class="col-sm-12" v-for="(abzar_right, index) in aj_bair_zar_baiguullaga_right_col" :key="index">
                            <div class="a_zar">
                              <div class="row">
                                <div class="col-sm-8 col-12 a_zar_pos">
                                  <p class="lp_1">
                                    <router-link :to="`/br_a_b_zar_details/${abzar_right.id}`" :bID="abzar_right.b_id">{{abzar_right.ajiliin_torol}}</router-link>
                                  </p>
                                  <p class="lp_2">
                                    <router-link :to="`/br_a_b_zar_details/${abzar_right.id}`" :bID="abzar_right.b_id">
                                      {{abzar_right.brigad_ner}}
                                    </router-link>
                                  </p>
                                  <p class="lp_3"><b>{{ 'where' | translate }}: </b> {{abzar_right.aimagname}}</p>
                                </div>
                                <div class="col-sm-4 a_zar_yline">
                                  <p class="lp_4">{{ 'anket_avah_ognoo' | translate }}</p>
                                  <p class="lp_5">{{abzar_right.e_ognoo}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Scroll>
                      </div>
                    </TabPane>
                    <TabPane :label="'zahialgat_ajil_zaruud' | translate" name="name2">
                      <div class="row ">
                        <Scroll height="500">
                          <div class="col-sm-12" v-for="(abzar_right, index) in  ajliin_zar_baiguullaga_right_col" :key="index">
                            <div class="a_zar">
                              <div class="row">
                                <div class="col-sm-8 col-12 ">
                                  <p class="lp_1">
                                    <router-link :to="`/br_a_z_details/${abzar_right.id}`"> {{abzar_right.ajiliin_torol}} </router-link>
                                  </p>
                                  <p class="lp_2">
                                    <router-link :to="`/br_a_z_details/${abzar_right.id}`">
                                      {{abzar_right.ner_mnbaiguullaga}}
                                    </router-link>
                                  </p>
                                  <p class="lp_3"><b>{{ 'where' | translate }}: </b> {{abzar_right.aimagname}}</p>
                                </div>
                                <div class="col-sm-4 a_zar_yline">
                                  <p class="lp_4">{{ 'anket_avah_ognoo' | translate }}</p>
                                  <p class="lp_5">{{abzar_right.e_ognoo}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Scroll>
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 brgd_tan_lc_bottomcont">
                <div class="brgd_tan_lc_bottomcont_sub">
                  <p class="p1"><span>{{ 'holboo_barih' | translate }}</span></p>
                  <div class="brgd_tan_lc_bottom1">
                    <div class="imgs">
                      <Tooltip placement="left" content="Twitter" v-if="linkAnalyze(bt_data.twitter)!=''">
                        <a :href="linkAnalyze(bt_data.twitter)"><img src="../../assets/pic/twitter_white_logo.png" alt=""/></a>
                      </Tooltip>
                      <Tooltip placement="left" content="Холбоос байхгүй" v-else>
                        <a ><img src="../../assets/pic/twitter_white_logo.png" alt=""/></a>
                      </Tooltip>
                      <Tooltip placement="left" content="YouTube"  v-if="linkAnalyze(bt_data.youtube)!=''">
                        <a :href="linkAnalyze(bt_data.youtube)"> <img src="../../assets/pic/youtube_white_logo.png" alt=""/></a>
                      </Tooltip>
                      <Tooltip placement="left" content="Холбоос байхгүй" v-else>
                        <a > <img src="../../assets/pic/youtube_white_logo.png" alt=""/></a>
                      </Tooltip>
                      <Tooltip placement="left" content="Facebook"  v-if="linkAnalyze(bt_data.face)!=''">
                        <a :href="linkAnalyze(bt_data.facebook)"> <img src="../../assets/pic/fb_white_logo.png" alt=""/></a>
                      </Tooltip>
                      <Tooltip placement="left" content="Холбоос байхгүй" v-else>
                        <a> <img src="../../assets/pic/fb_white_logo.png" alt=""/></a>
                      </Tooltip>
                    </div>
                    <p class="p2" v-if="bt_data.phone_one !=''"> <Icon type="md-call"/> &nbsp; + (976) {{ bt_data.phone_one }} </p>
                    <p class="p3" v-if="bt_data.phone_two !=''"> <Icon type="md-call"/> &nbsp; +(976) {{ bt_data.phone_two }} </p>
                    <p class="p4" v-if="bt_data.mail !=''"> <Icon type="md-mail"/> &nbsp; {{ bt_data.mail }} </p>
                    <p class="p5" v-if="bt_data.web !=''"> <Icon type="md-globe"/> &nbsp;  {{ bt_data.web }} </p>
                  </div>
                  <div class="brgd_tan_lc_bottom2">
                    <p class="p6"><span>{{ 'hayag' | translate }}</span></p>
                    <div class="row">
                      <div class="col-sm-1">
                        <Icon type="md-map"/>
                      </div>
                      <div class="col-sm-11">
                        <p> Монгол улс,
                          <span v-if="bt_data.aimagname !=''"> {{bt_data.aimagname}}, </span>
                          <span v-if="bt_data.sumname !=''"> {{bt_data.sumname}}, </span>
                          <span v-if="bt_data.bagname !=''"> {{bt_data.bagname}}, </span>
                          <span v-if="bt_data.gudam !=''"> {{bt_data.gudam}}-р гудамж,  </span>
                          <span v-if="bt_data.toot !=''"> {{bt_data.toot}} тоот.  </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>

    <!-- MODAL -&#45;&#45; Гүйцэтгэсэн ажлын дэлгэрэнгүй-->
    <Modal v-model="modal1" width="800"  :title="'Details_work_performed' | translate">
      <div class="brgd_guits_ajil_del">
        <div class="right_col_cont">
          <div class="modal-gallery">
            <div class="row">
              <div class="col-7 modal-gallery-left">
                <div class="modal-gallery-left-image" v-show="index === 0" :key="index" v-for="(zurag, index) in zurags">
                  <router-link :to="image_url_mbua + zurag.response">
                    <img :src="image_url_mbua + zurag.response" >
                  </router-link>
                </div>
              </div>
              <div class="col-5 modal-gallery-right">
                <div>
                  <div class="banner_one">
                    <div class="banner_image" v-show="index === 1" :key="index" v-for="(zurag, index) in zurags">
                      <router-link :to="image_url_mbua + zurag.response">
                        <img :src="image_url_mbua + zurag.response" >
                      </router-link>
                    </div>
                  </div>
                  <div class="between"></div>
                  <div class="banner_one">
                    <div class="banner_image" v-show="index === 2" :key="index" v-for="(zurag, index) in zurags">
                      <router-link :to="image_url_mbua + zurag.response">
                        <img :src="image_url_mbua + zurag.response" >
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row gad_main_txt">
            <div class="col-sm-12">
              <p class="title">{{this.guitset_ajil_delg.guitsetgsen_ajil}}</p>
              <p class="txt_cont" v-html="this.guitset_ajil_delg.toim"> </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 busad_medeelel_cont_div">
              <p><b>{{ 'busad_medeelel' | translate }}</b></p>
              <div class="row">
                <div class="col-md-6 col-sm-12 busad_medeelel_txts">
                  <div class="bm_border">
                    <p class="grey_txt">{{ 'ajliin_turul' | translate }}</p>
                    <p class="simple_txt">{{this.guitset_ajil_delg.brigad_uil_ajilgaa_chiglelcol}}</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 busad_medeelel_txts">
                  <div class="bm_border">
                    <p class="grey_txt">{{ 'aimag_niislel' | translate }}</p>
                    <p class="simple_txt">{{this.guitset_ajil_delg.aimagname}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-sm-12 busad_medeelel_txts">
                  <div class="bm_border">
                    <p class="grey_txt">{{ 'sum_duureg' | translate }}</p>
                    <p class="simple_txt">{{this.guitset_ajil_delg.sumname}}</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 busad_medeelel_txts">
                  <div class="bm_border bttmtxt">
                    <p class="grey_txt">{{ 'Date_commencement_work' | translate}}</p>
                    <p class="simple_txt">{{guitset_ajil_delg.e_ognoo | date}}</p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-12 busad_medeelel_txts">
                  <div class="bm_border bttmtxt">
                    <p class="grey_txt"> {{ 'Date_completion_work' | translate }}</p>
                    <p class="simple_txt">{{guitset_ajil_delg.d_ognoo | date }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-sm-12 zaxialagch_info">
              <p class="zax_title">{{'customer' | translate}}: {{this.guitset_ajil_delg.brigad_ner}}</p>
              <p class="zax_txt">
                <Icon type="md-call"/> &nbsp; + (976) {{this.guitset_ajil_delg.utas}}
              </p>
              <p class="zax_txt">
                <Icon type="md-mail"/> &nbsp; {{this.guitset_ajil_delg.mail}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
      </div>
    </Modal>

      <!-- MODAL -&#45;&#45; Гүйцэтгэсэн ажлын дэлгэрэнгүй-->
    <Modal v-model="sanalShow" :title="'send_feedback' | translate" footer-hide width="600px">
      <dataform ref="sanalForm" v-if="sanalShow" schemaID="373" :public="true" :onReady="readySanalForm" :onSuccess="onSuccess" />
    </Modal>

    <Modal v-model="sanalShow" :title="'send_feedback' | translate" footer-hide width="600px">
      <dataform ref="sanalForm" v-if="sanalShow" schemaID="370" :public="true" :onReady="readySanalForm" :onSuccess="onSuccess" />
    </Modal>
  </div>

<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <Button type="primary"-->
<!--                @click="sanalShow = true">Санал хүсэлт-->
<!--        </Button>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue')

import {
  IMAGE_URL,
  GET_BRIGAD_SRCH_ID,
  GET_BRIGAD_AJLIIN_ZAR,
  GET_BRIGADIIN_UNELGEE,
  GET_BRIGAD_GUITSET_AJIL_ID,
  GET_BRIGAD_GUITSET_AJIL_DEL,
  GET_BRIGAD_GUITSETGEL_UNELGEE,
  GET_BRIGAD_AJLIIN_ZAR_PAGINATE,
  GET_BRIGAD_AJLIIN_BAIR_ZAR_PAGINATE,
  GET_BRIGAD_AJLIIN_BAIR_ZAR_RIGHT_COL,
} from "@/graphql/queries";

const save_button_brigad_ajil_zar = () => import(/* webpackChunkName: "save_button_brigad_ajil_zar" */ '../section/save_button_brigad_ajil_zar');
const save_button_brigad_ajil_bair_zar = () => import(/* webpackChunkName: "save_button_brigad_ajil_bair_zar" */ '../section/save_button_brigad_ajil_bair_zar');
export default {
  name: "brgd_tanilts",
  props: ['id'],
  data() {
    return {
      loading_main: true,
      sanalShow:false,
      tab: '',

      //AJLIIN ZAR paginate vars
      current_page: 1,
      last_page: 1,
      total: 0,

      //AJLIIN BAIR zar vars
      current_page_b: 1,
      last_page_b: 1,
      total_b: 0,

      //GUITSETGESEN AJLUUD
      current_page_ga: 1,
      last_page_ga: 1,
      total_ga: 0,

      value: 4,
      modal1: false,

      //Xamtragch baiguullagaiin SWIPER controller array!!!
      medee_medeelel_slide: {
        slidesPerView: 5,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 15
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          414: {
            slidesPerView: 3,
            spaceBetween: 15
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 15
          }
        }
      },

      //baiguullaga /bt/ data
      bt_data:{},
      bt_ua_chiglel:{},
      bt_xamtragch_baiguullaga:{},
      bt_xamtragch_bai:{},
      zurags:[],

      //baiguullagatai xolbootoi ajliin zar, ajliin bairnii zar, guitsetgesen ajil
      b_ajliin_zar:[],
      b_ajliin_zar_1:[],
      b_ajliin_bair_zar:[],
      image_url_mbua: IMAGE_URL,
      guitset_ajil_srch_bid:[],
      guitset_ajil_delg:{},

      aj_bair_zar_baiguullaga:[],
      ajliin_zar_baiguullaga_right_col:[],
      aj_bair_zar_baiguullaga_right_col:[],


      //rate brigad
      rate_brigad: [],
      //tulbur tootsoo
      rate_b_cash: 0,
      rate_b_cash_count: 0,
      //гэрээт ажлын гүйцэтгэл
      rate_excution: 0,
      rate_excution_count: 0,
      // ajilchdiin chadamj
      rate_emp_potential: 0,
      rate_emp_potential_count: 0,
      //guitsetgesen_aj_xariutslaga
      rate_incumbency: 0,
      rate_incumbency_count: 0,
      //tsag barilt
      rate_correcthess: 0,
      rate_correcthess_count: 0,

      // rate brigadiin ajliin guitsetgel unelgee
      rate_guitsetgel: [],

      rate_guits_cash: 0,
      rate_guits_cash_count: 0,

      rate_guits_accord_plan: 0,
      rate_guits_accord_plan_count: 0,

      rate_exp_decision: 0,
      rate_exp_decision_count: 0,

    }
  },
  mounted() {
    this.getBrigad_Srch_Id();
    this.getGuitsetgesenAjluudPaginate();
    this.rightColAjBairZar();
    this.rightColAjliinZar();
    this.getBaigAjliinZarPaginate();
    this.getAjliinBairZarPaginate();
    this.getBrigadUnelgee();
    this.getBrgdGuitsetgelUnelgee();
  },
  components: {
    "save_button_brigad_ajil_zar": save_button_brigad_ajil_zar,
    "save_button_brigad_ajil_bair_zar": save_button_brigad_ajil_bair_zar,
    "dataform": Dataform,
  },



  methods:{
    readySanalForm() {
      this.$refs.sanalForm.setModel("brigad_id", this.id*1, "select");
    },
    onSuccess() {
      this.sanalShow = false;
    },

    //baiguulgiin delgerengvi medeelel
    getBrigad_Srch_Id(){
      this.loading_main = true;
      this.$apollo.query({ query: GET_BRIGAD_SRCH_ID, variables: {b_id: this.id.toString()} }).then(({data}) => {
        this.bt_data = data.ds_brilgas_view[0];
        this.bt_ua_chiglel = this.bt_data.ds_sub_brigad_uil_ajilgaa_chiglel_view;
        this.bt_xamtragch_baiguullaga = this.bt_data.ds_hamtragch_baiguullaga_view;
        setTimeout(() => {
          this.loading_main = false;
        })
      });
    },

    // baiguulgaas zarlsan AJLIIN ZAR - PAGINATE
    getBaigAjliinZarPaginate(){
      this.$apollo.query({
        query: GET_BRIGAD_AJLIIN_ZAR_PAGINATE,
        variables: {page: this.current_page, size: 10, id: this.id.toString()}
      }).then(({data}) => {
        this.b_ajliin_zar = data.paginate.ds_brigad_ajil_zar_view;
        this.total = data.paginate.total;
      })
    },

    //AJLIIN ZAR
    changePage(page) {
      this.current_page = page;
      this.getBaigAjliinZarPaginate();
    },

    // baiguulgaas zarlsan AJLIIN BAIR zar - PAGINATE
    getAjliinBairZarPaginate(){
      this.$apollo.query({
        query: GET_BRIGAD_AJLIIN_BAIR_ZAR_PAGINATE,
        variables: {page: this.current_page_b, size: 10, id: this.id}
      }).then(({data}) => {
        this.aj_bair_zar_baiguullaga = data.paginate.ds_brigad_ajilin_bair_zar_view;
        this.total_b = data.paginate.total;
      });


    },
    rightColAjBairZar(){
      this.$apollo.query({
        query: GET_BRIGAD_AJLIIN_BAIR_ZAR_RIGHT_COL,
      }).then(({data}) => {
        this.aj_bair_zar_baiguullaga_right_col = data.ds_brigad_ajilin_bair_zar_view;
      });
    },
    rightColAjliinZar(){
      this.$apollo.query({
        query: GET_BRIGAD_AJLIIN_ZAR,
      }).then(({data}) => {
        this.ajliin_zar_baiguullaga_right_col = data.ds_brigad_ajil_zar_view;
      });
    },
    //AJLIIN BAIR zar
    changePageB(page) {
      this.current_page_b = page;
      this.getAjliinBairZarPaginate();
    },

    // GUITSETGESESN AJLUUD - PAGINATE
    getGuitsetgesenAjluudPaginate(){
      this.$apollo.query({
        query: GET_BRIGAD_GUITSET_AJIL_ID,
        variables: {page: this.current_page_ga, size: 10, id: this.id.toString()}
      }).then(({data}) => {
        this.guitset_ajil_srch_bid = data.paginate.ds_brigad_guitsetgesen_ajil_view;
        this.total_ga = data.paginate.total;
      })
    },
    //GUITSETGESESN AJLUUD zar
    changePageGa(page) {
      this.current_page_ga = page;
      this.getGuitsetgesenAjluudPaginate();
    },

    // guitsetgesen ajliin delgerengvi MODAL
    modalCall(gid){
      this.$apollo.query({ query: GET_BRIGAD_GUITSET_AJIL_DEL, variables: {gid: gid.toString()} }).then(({data}) => {
        this.guitset_ajil_delg = data.ds_brigad_guitsetgesen_ajil_view[0];
        this.zurags = JSON.parse(data.ds_brigad_guitsetgesen_ajil_view[0].zurag);
        this.modal1 = true;
      })
    },
    persPic(inarray){
      var pic_url = (JSON.parse(inarray))[0].response;
      return pic_url;
    },
    //xolboos link batalgaatai esexiig shalgax
    linkAnalyze(getlink){
      var link="";
      if(getlink != null){
        link = getlink.slice(0,11);
        if(link == "https://www" || link=="http://www."){ return getlink;} else{ return "";}
      } else {return "";}
    },

    //rate calculate
    getBrigadUnelgee(){
      this.$apollo.query({
        query: GET_BRIGADIIN_UNELGEE,
        variables: { id: this.id.toString()}
      }).then(({data}) => {
        this.rate_brigad = data.ds_unelgee_brigad_view;
        if(this.rate_brigad != null){
          for (let i=0; i < this.rate_brigad.length; i++){
            switch (this.rate_brigad[i].uzuulelt_id){
              case 1: this.rate_excution = this.rate_excution +  this.rate_brigad[i].unelgee_bri_id; this.rate_excution_count++; break;
              case 2: this.rate_b_cash = this.rate_b_cash +  this.rate_brigad[i].unelgee_bri_id; this.rate_b_cash_count++; break;
              case 3: this.rate_emp_potential = this.rate_emp_potential +  this.rate_brigad[i].unelgee_bri_id; this.rate_emp_potential_count++; break;
              case 4: this.rate_incumbency = this.rate_incumbency +  this.rate_brigad[i].unelgee_bri_id; this.rate_incumbency_count++; break;
              case 5: this.rate_correcthess = this.rate_correcthess +  this.rate_brigad[i].unelgee_bri_id; this.rate_correcthess_count++; break;
              default:  break;
            }
          }
          this.rate_b_cash  = Math.ceil(this.rate_b_cash / this.rate_b_cash_count);
          this.rate_excution = Math.ceil(this.rate_excution / this.rate_excution_count);
          this.rate_emp_potential = Math.ceil(this.rate_emp_potential / this.rate_emp_potential_count);
          this.rate_incumbency = Math.ceil(this.rate_incumbency / this.rate_incumbency_count);
          this.rate_correcthess = Math.ceil(this.rate_correcthess / this.rate_correcthess_count);
        }
      })
    },
    getBrgdGuitsetgelUnelgee(){
      this.$apollo.query({
        query: GET_BRIGAD_GUITSETGEL_UNELGEE,
      }).then(({data}) => {
        this.rate_guitsetgel = data.ds_brigad_guitsetgesen_ajil_view;
        if(this.rate_guitsetgel != null){
          for (let i=0; i < this.rate_guitsetgel.length; i++){
            this.rate_guitsetgel_sub = this.rate_guitsetgel[i];
            if(this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw != null){
              for(let j=0; j < this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw.length; j++) {
                if(this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw[j].brigad_id == this.id){
                  switch (this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw[j].uzuulelt_id) {
                    case 2: this.rate_exp_decision = this.rate_exp_decision + this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw[j].hangamj_id;
                            this.rate_exp_decision_count++; break;
                    case 3: this.rate_guits_accord_plan = this.rate_guits_accord_plan + this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw[j].hangamj_id;
                            this.rate_guits_accord_plan_count++;
                    break;
                    case 4: this.rate_guits_cash = this.rate_guits_cash + this.rate_guitsetgel_sub.ds_sub_brigad_uneleh_veiw[j].hangamj_id;
                            this.rate_guits_cash_count++;
                            break;
                    default: console.log("j++ >>> " + j++); break;
                  }
                }
              }
            }
          }
          this.rate_guits_accord_plan  = Math.ceil(this.rate_guits_accord_plan / this.rate_guits_accord_plan_count);
          this.rate_guits_cash = Math.ceil(this.rate_guits_cash / this.rate_guits_cash_count);
          this.rate_exp_decision = Math.ceil(this.rate_exp_decision / this.rate_exp_decision_count);
        }
      })
    },
  }
}

</script>

<style scoped>

</style>
